import React from 'react';
import {
    IMobilityBasicProfile,
    FallbackProfile,
    MobilityBasicProfile,
} from '@finn-no/profilebox';
import {
    MobilityExtendedProfile,
    MobilityExtendedProfileProps,
} from '@finn-no/extended-profilebox';
import './styles.css';

export type AppProps = {
    adId: number;
    companyProfile: IMobilityBasicProfile;
    extendedProfileMobility: MobilityExtendedProfileProps | null;
    deviceType: string;
};

const MobilityApp = ({
    adId,
    companyProfile,
    extendedProfileMobility,
    deviceType,
}: AppProps): React.ReactElement => {
    if (extendedProfileMobility) {
        return <MobilityExtendedProfile {...extendedProfileMobility} />;
    } else {
        switch (companyProfile.tag) {
            case 'Mobility':
                return (
                    <MobilityBasicProfile
                        adId={adId}
                        deviceType={deviceType}
                        bpm={companyProfile}
                    />
                );
            default:
                return (
                    <FallbackProfile msg="Noe feilet dessverre i forsøket på å vise kontaktinformasjonen på denne annonsen" />
                );
        }
    }
};

export default MobilityApp;
