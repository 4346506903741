import React from 'react';
import ReactDOM from 'react-dom';
import { RecommercePreview } from '@finn-no/recommerce-extended-profilebox';
import Fallback from './Fallback.js';
import RealestateApp from './RealestateApp.js';
import MobilityApp from './MobilityApp.js';
import { RealEstateOrRecommerceOrMobility } from '../common/types.js';
import RecommerceApp from './RecommerceApp.js';

class WebComponent extends HTMLElement {
    constructor() {
        super();
        const dataNode = this.querySelector(
            '[data-company-profile-data]',
        ) as HTMLElement;
        const initialState =
            dataNode &&
            dataNode.textContent &&
            (JSON.parse(
                dataNode.textContent,
            ) as RealEstateOrRecommerceOrMobility);
        if (initialState) {
            const innerNode = this.querySelector(
                '[data-company-profile]',
            ) as HTMLElement;

            if (initialState.type === 'fallback-hydration') {
                ReactDOM.hydrate(<Fallback />, innerNode);
            } else if (initialState.type === 'recommerce-preview-hydration') {
                ReactDOM.hydrate(<RecommercePreview />, innerNode);
            } else if (initialState.type === 'recommerce-hydration') {
                ReactDOM.hydrate(
                    <RecommerceApp {...initialState} />,
                    innerNode,
                );
            } else if (initialState.type === 'realestate-hydration') {
                ReactDOM.hydrate(
                    <RealestateApp
                        {...{
                            ...initialState,
                            companyProfile: initialState.companyProfile,
                        }}
                    />,
                    innerNode,
                );
            } else if (initialState.type === 'mobility-hydration') {
                ReactDOM.hydrate(
                    <MobilityApp
                        {...{
                            ...initialState,
                            companyProfile: initialState.companyProfile,
                        }}
                    />,
                    innerNode,
                );
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.log('missing data element', this, initialState);
            }
        }
    }
}

if (!customElements.get('company-profile-podlet')) {
    customElements.define('company-profile-podlet', WebComponent);
}
