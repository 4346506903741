import React from 'react';
import './styles.css';
import { RecommerceExtendedProfile } from '@finn-no/recommerce-extended-profilebox';
import type { RecommerceHydratationData } from '../common/types.js';

export type SubscriptionRequestObject = {
    unsubscribeUrl: string;
    subscribeUrl: string;
    requestObject: RequestInit;
};

const doSubscribe = (url: string, requestObject: RequestInit) =>
    fetch(url, requestObject).then((r) => {
        if (r.status === 200) {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    });

const RecommerceApp = (
    props: RecommerceHydratationData,
): React.ReactElement => (
    <RecommerceExtendedProfile
        {...props.extendedProfileRecommerce}
        subscribe={() =>
            doSubscribe(props.req.subscribeUrl, props.req.requestObject)
        }
        unsubscribe={() =>
            doSubscribe(props.req.unsubscribeUrl, props.req.requestObject)
        }
    />
);

export default RecommerceApp;
