import React from 'react';
import {
    IRealEstateBasicProfile,
    FallbackProfile,
    RealEstateBasicProfile,
} from '@finn-no/profilebox';
import {
    RealEstateExtendedProfile,
    RealEstateExtendedProfileProps,
} from '@finn-no/extended-profilebox';
import './styles.css';

export type AppProps = {
    adId: number;
    companyProfile: IRealEstateBasicProfile;
    extendedProfileRealEstate: RealEstateExtendedProfileProps | null;
    deviceType: string;
};

const RealestateApp = ({
    adId,
    companyProfile,
    extendedProfileRealEstate,
    deviceType,
}: AppProps): React.ReactElement => {
    if (extendedProfileRealEstate) {
        return <RealEstateExtendedProfile {...extendedProfileRealEstate} />;
    } else {
        switch (companyProfile.tag) {
            case 'RealEstate':
                return (
                    <RealEstateBasicProfile
                        adId={adId}
                        deviceType={deviceType}
                        bpre={companyProfile}
                    />
                );
            default:
                return (
                    <FallbackProfile msg="Noe feilet dessverre i forsøket på å vise kontaktinformasjonen på denne annonsen" />
                );
        }
    }
};

export default RealestateApp;
